import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  IoChatboxEllipsesOutline,
  IoHeartOutline,
  IoHeart,
} from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import Crown from "../images/crown.png";
// import { CiHeart } from "react-icons/ci";
import Home from "./Home";

const Slider = () => {
  const imageData = [
    {
      src: "https://i.pinimg.com/564x/96/9e/29/969e29466c4040aa181f7d80e6cac331.jpg",
      alt: "Home",
      price: "750,000$",
      location: "سلێمانی ئاشتی",
    },
    {
      src: "https://i.pinimg.com/564x/0f/8b/a5/0f8ba5b8d78ed7c43ababd190aafb282.jpg",
      alt: "Home",
      price: "850,000$",
      location: "سلێمانی ڕزگاری",
    },
    {
      src: "https://i.pinimg.com/564x/88/83/c9/8883c93ebe6fc86aed2ed72f117b306d.jpg",
      alt: "Home",
      price: "950,000$",
      location: "سلێمانی کارێزەووشک",
    },
    {
      src: "https://i.pinimg.com/564x/56/10/19/5610195ae334cf5f7f1f4e6893eb4d4e.jpg",
      alt: "Home",
      price: "650,000$",
      location: "سلێمانی بەختیاری",
    },
    {
      src: "https://i.pinimg.com/564x/9f/e3/01/9fe30127806487440ab6a36ee165d58e.jpg",
      alt: "Home",
      price: "550,000$",
      location: "سلێمانی هەواری شار",
    },
  ];

  const [fill, setFill] = useState(Array(imageData.length).fill(false));

  const handleheart = (index) => {
    const updateFill = [...fill];
    updateFill[index] = !updateFill[index];
    setFill(updateFill);
  };

  return (
    <>
      <div className="mt-6 flex justify-around z-10 relative">
        <Link
          to={Home}
          className="rounded-lg bg-primary-200 px-2 py-2 text-white hover:bg-primary-100 "
        >
          خانوو
        </Link>
        <Link
          to={Home}
          className=" rounded-lg bg-primary-200 px-2 py-2 text-white hover:bg-primary-100"
        >
          شوقە
        </Link>
        <Link
          to={Home}
          className=" rounded-lg bg-primary-200 px-2 py-2 text-white hover:bg-primary-100"
        >
          زەوی
        </Link>
      </div>
      <div className="carousel carousel-center mt-4 w-full gap-2 px-2">
        {imageData.map((image, index) => (
          <div className="carousel-item" key={index}>
            <div className="card relative">
              <img
                src={Crown}
                className="absolute left-3 h-8 w-8 bg-[#e1b600] rounded-b-lg"
                alt="h"
                />
              <img
                src={image.src}
                className="h-40 w-40 rounded-lg "
                alt={image.alt}
              />
              <div className="flex space-x-6">
                <h6 className="card-title">{image.price}</h6>
                <div className="flex items-center text-primary-100">
                  <IoChatboxEllipsesOutline />
                  <div className="" onClick={() => handleheart(index)}>
                    {fill[index] ? (
                      <IoHeart style={{ color: "#ed4956" }} />
                    ) : (
                      <IoHeartOutline />
                    )}
                  </div>
                  <FiPhoneCall />
                </div>
              </div>
              <p className="text-md font-thin">{image.location}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Slider;
