import React from "react";

const Location = () => {

  return (
    <div>
      <h2>Select a Location</h2>
      {/* Add your logic for selecting a location here */}
    </div>
  );
};

export default Location;
