import React from 'react'

const P404 = () => {
  return (
    <div>
      <del>Page 404, Not Found</del>
    </div>
  )
}

export default P404
