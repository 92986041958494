import React from "react";
import {
    IoChatboxEllipsesOutline,
    IoHeartOutline,
  } from "react-icons/io5";
  import { FiPhoneCall } from "react-icons/fi";
import Blawkrawakan from "./Blawkrawakan";
import '../fonts/font2.css';
const Profile2 = () => {
  return (
    <section>
      <div className="h-[270px] flex-col space-y-4 bg-primary-100">
        <div className="text-center">
          <h1 className="pt-4 text-3xl text-white font-kurdish_043">Profile</h1>
        </div>
        {/* پڕۆفایل */}
        <div className="flex justify-center">
          <div className="avatar flex-col justify-center align-middle">
            <div className="w-24 rounded-full">
              <img src="https://i.pinimg.com/564x/53/05/f4/5305f4c7513b35fbf9117018204edff3.jpg" alt=""/>
            </div>
            <p className="pt-2 font-thin text-white font-kurdish_041">حاکم حامد عمر</p>
          </div>
        </div>
        <div className="flex gap-4 justify-center">
            <IoChatboxEllipsesOutline className="border-non rounded-full p-[4px] bg-[#4d3db0]" size={40}  color="white"/>
            <IoHeartOutline className="border-non rounded-full p-[4px] bg-[#4d3db0] " size={40} color="white"/>
            <FiPhoneCall className="border-non rounded-full p-[6px] bg-[#4d3db0]" size={40} color="white"/>
        </div>
      </div>
      {/* بەشی بڵاوکراواکان */}
      <Blawkrawakan/>
    </section>
  );
};

export default Profile2;
