import React from "react";
import {
    IoChatboxEllipsesOutline,
  } from "react-icons/io5";
  import { FiPhoneCall } from "react-icons/fi";
import '../fonts/font2.css';
const Blawkrawakan = () => {
  return (
    <>
      <div className="mt-4">
        <div className="mt-4 flex justify-center">
          <p className="w-[100px] rounded-[48.5px] border-none bg-[#4d3db0] text-center text-white font-kurdish_043">
            بڵاوکراوەکان
          </p>
        </div>
      </div>
      {/* پۆستی پڕۆفایل */}
      <div className="h-[250px] w-full flex-row mt-4">
        <div className="h-[94px] flex flex-row-reverse border-2 rounded-xl shadow-lg mx-[30px] bg-[#f3f3f3]">
          <img
            className="flex max-sm:w-30 h-[90px] align-middle py-[4px] w-[100px] mx-2 rounded-xl"
            src="https://i.pinimg.com/564x/3e/2d/90/3e2d90bc910250ad93cad42b2309e165.jpg"
            alt=""
          />
          <div className="w-full flex justify-between">
            <div className="flex flex-col justify-between py-4 mx-2">
                <FiPhoneCall size={20} />
                <IoChatboxEllipsesOutline className="flex justify-end" size={20}/>
            </div>
            <div className="flex flex-col justify-around space-y-4 py-2 max-sm:[140px] sm:w-[50%]">
                <p className="flex text-end font-kurdish_043">سلێمانی-ڕزگاری</p>
                <p className="flex justify-end font-kurdish_042">$20,000</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Blawkrawakan;
