import React, { useState } from "react";

const Filter = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <section
      className="absolute right-0 top-[120px] z-50 float-right mr-6 h-40 w-40 rounded-md
     border-2 border-white bg-slate-50 shadow-md"
    >
      <div className="py-2">
        <div className="dropdown flex justify-end" onClick={toggleDropdown}>
          <div tabIndex={0} role="button" className="border border-slate-200 rounded-md bg-slate-400 w-20 text-center m-1">
            Click
          </div>
          {isDropdownOpen && (
            <ul
              tabIndex={0}
              className="menu dropdown-content z-[1] w-40 rounded-box bg-slate-50 p-2 shadow"
            >
              <li>
                <a>Item 1</a>
              </li>
              <li>
                <a>Item 2</a>
              </li>
            </ul>
          )}
        </div>
        <div className="px-2">
          <label className="flex justify-end gap-4 w-full text-right cursor-pointer pb-2">
            <span className="label-text font-medium">دهۆک</span>
            <input type="checkbox" className="checkbox-success checkbox" />
          </label>
          <label className="flex justify-end gap-4 w-full cursor-pointer pb-2">
            <span className="label-text font-medium">دهۆک</span>
            <input type="checkbox" className="checkbox-success checkbox" />
          </label>
          <label className="flex justify-end gap-4 w-full cursor-pointer pb-2">
            <span className="label-text font-medium">دهۆک</span>
            <input type="checkbox" className="checkbox-success checkbox" />
          </label>
        </div>
      </div>
    </section>
  );
};

export default Filter;
