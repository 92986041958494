import "./App.css";
import Home from "./BasheHome/Home";
import Profile2 from "./basheProfile/Profile2";
import Navigation from "./basheNav/Navigation";
import Location from "./pageLocation/Location"
import P404 from "./P404";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

function App() {
  return (
    <div className="h-screen">
      <BrowserRouter>
        <Routes>
          <Route path="/" element ={<Home />} />
          <Route path="/profile2" element ={<Profile2 />} />
          <Route path="/location" element ={<Location />} />
          <Route path="*" element ={<P404 />} />
          <Route path="" element={<Navigate to="/home" replace />} />
        </Routes>
      <Navigation />
      </BrowserRouter>
      
    </div>
  );
}

export default App;
