import React from "react";
import Banner from "./Banner";
import Slider from './Slider';
import Post from './Post';


const Home = () => {
  return (
    <>
      <Banner />
      <Slider />
      <Post/>
    </>
  );
};

export default Home;
