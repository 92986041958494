import React, {useState} from "react";
import { LuFilter } from "react-icons/lu";
import { LuFilterX } from "react-icons/lu";
import { LiaSearchSolid } from "react-icons/lia";
import { Link } from "react-router-dom";
import Filter from "./Filter";

const Banner = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleFilterClick = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  return (
    <>
    <div className="h-36 w-full bg-primary-200 relative z-20">
      <div className="flex flex-row-reverse justify-between pt-4">
        <div className="avatar pr-2">
          <div className="w-12 rounded-full">
            <img
              src="https://i.pinimg.com/564x/53/05/f4/5305f4c7513b35fbf9117018204edff3.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="flex items-center text-center">
          <h2 className="font-bold text-white">سـەرەکــی</h2>
        </div>
        <div className="flex items-center">
          <Link
            to="/"
            className="to bg-pink-500 bg-gradient-to-r from-white bg-clip-text pl-4 text-xl font-bold text-transparent"
          >
            VIP
          </Link>
        </div>
      </div>

      <div className="relative flex items-center justify-between p-[14px]">
        <LiaSearchSolid
          className="absolute right-16 top-[23px] font-thin text-white pointer-events-none stroke-current opacity-60"
          size={26}
        />
        <input
          type="text"
          className="h-10 w-full rounded-md border border-white bg-transparent pr-11 text-right text-white caret-white focus:border-white focus:outline-none"
          placeholder="گەڕان بکە"
        />
        <div className="flex">
        {isFilterOpen ? (
            <LuFilterX
              className="h-8 w-10 text-white"
              onClick={handleFilterClick}
            />
          ) : (
            <LuFilter
              className="h-8 w-10 text-white"
              onClick={handleFilterClick}
            />
          )}
        </div>
      </div>
    </div>
    {isFilterOpen && <Filter/> }
    </>
  );
};

export default Banner;
